import React, { useState } from 'react';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import LinearProgress from '@mui/material/LinearProgress';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import axios from 'axios';
import logo from './lunit-logo.png';

function Copyright(props) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      <Link color="inherit" href="https://lunit.care/">
        Lunit CARE
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const theme = createTheme({
  typography: {
    fontFamily: [
      'Noto Sans KR',
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
  },
});

export default function App() {
  const [answer, setAnswer] = useState('');
  const [progress, setProgress] = useState(0);

  const handleSubmit = (event) => {
    event.preventDefault();
    setAnswer('');
    setProgress(0);
    const data = new FormData(event.currentTarget);
    const q_original = data.get('q_original');
    console.log({
      q_original: q_original,  
    });
    axios.post('https://kogpt3-backend.lunit.ai/translate/', {
      src_text: q_original,
      dst_language: 'en'
    })
    .then((response)=>{
      setProgress(30);
      const q_en = response.data.dst_text;
      console.log(q_en);
      axios.post('https://kogpt3-backend.lunit.ai/qna/', {
        q_text: q_en
      })
      .then((response)=>{
        setProgress(60);
        const a_en = response.data.a_text;
        console.log(a_en);
        axios.post('https://kogpt3-backend.lunit.ai/translate/', {
          src_text: a_en,
          dst_language: 'ko'
        })
        .then((response)=>{
          setProgress(100);
          const a_ko = response.data.dst_text;
          console.log(a_ko);
          setAnswer(a_ko);
        })
        .catch((error)=>{console.log(error); setProgress(0);});
      })
      .catch((error)=>{console.log(error); setProgress(0);});
    })
    .catch((error)=>{console.log(error); setProgress(0);});
  };

  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xl">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <img src={logo} alt="Logo" width="64px" style={{margin:16+'px'}}/>
          <Typography component="h1" variant="h5" sx={{margin:20+'px'}}>
            루닛케어 인공지능 솔루션
          </Typography>
	  <Card sx={{  width: '90%' }} variant="outlined">
            <CardContent>
              <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  id="q_original"
                  label="무엇이 궁금하신가요?"
                  name="q_original"
                  autoFocus
                  multiline
                />
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  sx={{ mt: 3, mb: 2}}
                >
                  인공지능에게 물어보기
                </Button>
              </Box>
              <Box>
                {progress > 0 && <LinearProgress variant="determinate" value={progress} /> }
                {answer.length > 0 &&
                  <><h3>인공지능 답변</h3>{answer}</>
                }
              </Box>
            </CardContent>
          </Card>
        </Box>
        <Copyright sx={{ mt: 8, mb: 4 }} />
      </Container>
    </ThemeProvider>
  );
}

